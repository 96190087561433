<template>
  <div class="infoopen">
    <div class="info-container">
     <div class="infotitle">
        <span>信息公开</span>
    </div>
    <div class="info-content">
        <Infotablist @listenTochild="showMsgFromChild" :currentIndex="currentIndex"></Infotablist>
        <Infotabcontent :title="tabtitle"></Infotabcontent>
    </div>
    </div>
  </div>
</template>

<script>
import Infotablist from './components/infotablist'
import Infotabcontent from './components/infotabcontent'
export default {
  components : {
    Infotablist,
    Infotabcontent
  },
  name: 'Infoopen',
  data () {
      return {
          tabtitle: "通知公告",
          currentIndex:1
      }
  },
  methods: {
    showMsgFromChild(title) {
        this.tabtitle = title
    }
  }
}
</script>

<style lang="less" scoped>
.infoopen {
    background-color: #f5f5f5;
    .info-container {
    width: 1200px;
    min-height: 400px;
    padding-bottom: 40px;
    margin: 0 auto;
    background-color: #f5f5f5;

    &::before {
        display: block;
        content: ".";
        clear: both;
        height: 0;
        overflow: hidden;
        visibility: hidden; 
    }
    .infotitle  {
        margin-top: 10px;
        font-size: 23px;
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        span {
            &:nth-child(1) {
            display: flex;
                &::before {
                content: "";
                display: block;
                height: 20px;
                width: 35px;
                background: url('../../assets/img/title.png') no-repeat center center;
                }
            }
        }

    }
    .info-content {
       min-height: 100px;
       background-color: #f5f5f5;
       display: flex;
    }
}
}
</style>